// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

//RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

@import "./icons.scss";


// Theme Custom Scss for react version
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}
.rdw-editor-main {
  border: 1px solid $card-border-color !important;
  border-radius: 4px !important;
  min-height: 130px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.dataTables_filter {
  float: right;
}

.dataTables_paginate .pagination {
  float: right;
}

.rdw-editor-wrapper {
  min-height: 180px !important;
}

.ReactModal__Overlay {
  z-index: 10001 !important;
}

.react-datepicker-wrapper {
  .form-control {
    width: 405% !important;
  }
}

.dropzone {
  h3 {
    text-align: center !important;
    padding-top: 100px !important;
  }
  div {
    min-height: 200px !important;
  }
  min-height: 200px !important;
}

input[type="radio"] + label {
  cursor: pointer !important;
}

.topnav .navbar-nav .nav-item .nav-link.active {
  color: $primary !important;
}
.input-color{
  color: $input-color !important;
}
.rangeslider__fill{
  background-color: $primary !important;
}
.rating-container {
  background-color: transparent !important;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(../fonts/Roboto-Bold.ttf) format('truetype');
}

.relative-container {
  position: relative;
  float:left;
  height:600px;
}

.relative-container svg {
  position: absolute;
}

.emergency-panel {
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 10px;
  width: 165px;
}

.button-emergency {
  background-color: red;
  color: white
}

.emergency-panel .row {
  margin-bottom: 10px;
}

.mode-panel {
  position: absolute;
  z-index: 100;
  left: 10px;
  bottom: 20px;
  padding: 15px;
  width: 165px;
}

.mode-panel .row {
  padding-top: 6px;
  padding-bottom: 6px;
}
.alert {
  position: absolute;
  padding: 0.75rem 1.25rem;
  top: -60px;
  width: 400px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.mode-panel .button, .emergency-panel .button {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10px;
  text-align: center;
  border: none;
  text-decoration: none;
  display: inline-block;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  filter: drop-shadow(0.15rem 0.25rem 0.3rem rgba(0, 0, 0, 0.5));
}

.logo-lg img {
  height: 60px;
}

.mode-button {
  width:100%;
  white-space: nowrap;
}

.small-button {
  width: 65px;
}

.mode-panel .container {
  padding: 2px 0px 2px 0px;
}
.button-active {
  background-color: #707070;
  color: white
}

.button-inactive {
  background-color: #CCC8C8;
  color: #080808;
}

.button-primary {
  background-color: #28A745;
  color: white;
}

.button-secondary {
  background-color: #17A2B8;
  color:white;
  margin-right:10px;
}

.button-emergency {
  background-color: red;
  color: white
}

.emergency-panel .row {
  margin-bottom: 10px;
}

.size-20 {
  font-size: 20px;
}

.size-27 {
  font-size: 27px;
}

.message-area, .online-area {
  position: relative;
  display: block;
  width: fit-content;
  padding: 0px 5px;
  float: left;
  font-size: 14px;
  margin-right: 10px;
}
.status-panel {
  position: absolute;
  right: 10px;
  top: 5px;
  background-color: #efebef;
  height: 25px;
  width: fit-content;
  display: block;
  padding: 0px 4px 0px 4px;
  z-index: 999;
  border-radius: 3px;
  border: 1px solid #D8D2D2;
}

.offline-status-panel {
  position: absolute;
  right: 10px;
  top: 5px;
  background-color: red;
  height: 25px;
  width: fit-content;
  display: block;
  padding: 1px 4px 0px 4px;
  color: white;
  z-index: 999;
  border-radius: 3px;
  border: 1px solid rgb(118, 3, 3);
}

.tab-content {
  margin-top: 25px;
}

.time {
  position: relative;
  display: block;
  float:right;
  z-index: 100;
  width: 135px;
  background-color: transparent;
  text-align: left;
  font-size: 14px;
}

.web-status-panel {
  position: relative;
  width: 960px;
}

.web-offline-panel {
  position: relative;
  width: 960px;
  top: 30px;
}

.parameter-text {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  line-height: 2.5em;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.parameter-description {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 2.5em;
  text-align: left;
}

.tab-pane {
  height: 340px;
  padding: 20px;
}

.tab-content .row {
  padding: 8px 0px 8px 0px;
}


nav.nav.nav-tabs {
  margin-top: 20px;
  padding-left: 20px;
  padding-top: 10px;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 16px;
}

.nav-tabs {
  border-bottom: 1px solid #ced4da !important;
}

.nav-link {
  color: black;
}

.settings-footer {
  padding: 10px 0px 5px 0px;
}

.button-active {
  background-color: #707070;
  color: white
}

.button-inactive {
  background-color: #CCC8C8;
  color: #080808;
}

.button-primary {
  background-color: #28A745;
  color: white;
}

.button-secondary {
  background-color: #17A2B8;
  color:white;
  margin-right:10px;
}

.regular-button {
  width:125px;
  white-space: nowrap;
}

.column-title {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}

.column-sub-title {
  font-family: Roboto, sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
}

.input-digit-big {
  width: 70px;
  display: inline-block;
}

.remove-padding {
  padding-left: 0px;
}

.input-digit-small {
  width: 40px;
  text-align: center;
  margin: 3px;
}

.digit-small {
  text-align: center;
}

.digit-small input {
  display: inline-block;
}

.sensor-table .row {
  padding: 4px 0px 4px 0px;
}
.unit-text {
  font-weight: normal;
  font-size: 13px;
  display: block;
  height: 13px;
  text-align: center !important;
}

.select-menu {
  width: 85% !important;
  margin-left: 20px;
  margin-top: 60px;
}

.sensor-title {
  display: block;
  float:left;
}

.current-reading {
  display: block;
  float:left;
  text-align: center;
  position: relative;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  width: 60px;
  margin-left: 10px;
  background-color:#d8d2d2;
}

.rows-clickable tr {
  cursor: pointer;
}

.units-panel {
  position: absolute;
  left: 25px;
  top: 575px;
  width: fit-content;
  display: block;
  padding: 0px 4px 0px 4px;
  z-index: 999;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  text-align: right;
}

.motor-temp-panel {
  position: absolute;
  left: 25px;
  top: 65px;
  width: fit-content;
  display: block;
  padding: 0px 4px 0px 4px;
  z-index: 999;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  text-align: right;
}
.controller-temp-panel {
  position: absolute;
  left: 25px;
  top: 85px;
  width: fit-content;
  display: block;
  padding: 0px 4px 0px 4px;
  z-index: 999;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  text-align: right;
}

.carousel-control-next {
  right: -15px;
}

.carousel-control-prev {
  left: -15px;
}

.carousel-indicators {
  bottom: -30px!important;
}

.changing-mode {
  position: absolute;
  left: 10px;
  bottom: 15px;
  padding: 15px;
  width: 165px!important;
  padding-top: 70px!important;
  height:200px;
  white-space: pre-line;
  text-align: center!important;
  width: fit-content;
  display: block;
  padding: 0px 4px 0px 4px;
  z-index: 999;
  opacity: 0.95;
  background-color: #FFFFFF;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  text-align: right;
}


.error-message {
  position: absolute;
  top: 2px;
  left: 460px;
  z-index: 1000;
  font-size: 14px;
  text-align: left;
  width:265px;
  background: red;
  color: white;
  font-family: Lato;
  padding: 10px 15px 10px 15px;
  overflow: hidden;
  border-radius: 4px;
}

.error {
  font-family: Lato;
  font-size: 14px;
  color: red;
  margin-top: -10px;
  margin-bottom:15px;
  text-align:start;
}

.keyboard-background {
  background-color: #00000070;
  position: absolute;
  width: 860px;
  height: 75%;
  z-index: 1000;
}

.numeric-keyboard {
  position: absolute;
  width: 85%;
  height: 85%;
  left: 45px;
  top: 35px;
  background-color: white;
  padding:20px;
  border-radius: 13px;
}

.numeric-keyboard .parameter-text, .numeric-keyboard input {
  margin-bottom: 20px;
}

.numeric-keyboard .regular-button {
  margin-top: 20px;
}

.settings-container {
  position: absolute;
  width:860px;
}

.date-buttons {
  position: absolute;
  right: 10px;
  bottom: 0;
  padding-bottom: 20px;
}

.jsx-4179805763 {
  width:100%;
} 

.navbar-brand-box {
width: 240px;
}